/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type CertonaPartsCarouselProps } from '../interface';
import ScrolledPartCardContainer from './ScrolledPartCardContainer';
import StackedPartCardContainer from './StackedPartCardContainer';
import PartsCarousel from '@/components/AZCustomComponent/PartsCarousel';

const CertonaPartsCarousel = (props: CertonaPartsCarouselProps) => {
  const {
    id,
    title,
    subtitle,
    partsList,
    pricingInfo,
    pageType,
    greyBackground,
    isFromExternal,
    onCertonaRecommendationClick,
    updateAfterAddtoCartClick,
    addToCartAnalyticsandSuccessMessage,
    skuDetailsData,
    containerID,
    thresholdHeaderText,
    inDrawer,
    certonaType,
    shelfSeoUrl,
    cardsContainerCustomClass,
    setAddedCertonaItemObject,
    getCtjAnalytics,
  } = props;

  if (!partsList || partsList.length < 0) {
    return null;
  }
  const isOosOrDoesNotFit = certonaType === 'OOS' || certonaType === 'DNF';
  const partsListForOos = isOosOrDoesNotFit ? partsList.slice(0, 3) : partsList;

  switch (true) {
    case containerID?.includes('horizontal_stacked'):
      return (
        <StackedPartCardContainer
          title={title}
          partsList={partsList}
          contentsAligment="horizontal"
          pageType={pageType}
          onCertonaRecommendationClick={onCertonaRecommendationClick}
          updateAfterAddtoCartClick={updateAfterAddtoCartClick}
          addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
          skuDetailsData={skuDetailsData}
          pricingInfo={pricingInfo}
          thresholdHeaderText={thresholdHeaderText}
          setAddedCertonaItemObject={setAddedCertonaItemObject}
          inDrawer={inDrawer}
          certonaType={certonaType}
          getCtjAnalytics={getCtjAnalytics}
        />
      );
    case containerID?.includes('horizontal_scrolled'):
      return (
        <ScrolledPartCardContainer
          id={id}
          title={title}
          subtitle={subtitle}
          partsList={partsListForOos}
          contentsAligment="horizontal"
          pageType={pageType}
          onCertonaRecommendationClick={onCertonaRecommendationClick}
          addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
          skuDetailsData={skuDetailsData}
          pricingInfo={pricingInfo}
          updateAfterAddtoCartClick={updateAfterAddtoCartClick}
          thresholdHeaderText={thresholdHeaderText}
          certonaType={certonaType}
          shelfSeoUrl={shelfSeoUrl}
          inDrawer={inDrawer}
          setAddedCertonaItemObject={setAddedCertonaItemObject}
        />
      );
    case containerID?.includes('large_stacked'):
      return (
        <StackedPartCardContainer
          title={title}
          partsList={partsList}
          contentsAligment="vertical"
          pageType={pageType}
          onCertonaRecommendationClick={onCertonaRecommendationClick}
          addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
          skuDetailsData={skuDetailsData}
          pricingInfo={pricingInfo}
          thresholdHeaderText={thresholdHeaderText}
          setAddedCertonaItemObject={setAddedCertonaItemObject}
          inDrawer={inDrawer}
          certonaType={certonaType}
          getCtjAnalytics={getCtjAnalytics}
        />
      );
    case containerID?.includes('large_scrolled'):
      return (
        <ScrolledPartCardContainer
          title={title}
          subtitle={subtitle}
          partsList={partsList}
          contentsAligment="vertical"
          pageType={pageType}
          onCertonaRecommendationClick={onCertonaRecommendationClick}
          addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
          skuDetailsData={skuDetailsData}
          pricingInfo={pricingInfo}
          thresholdHeaderText={thresholdHeaderText}
          setAddedCertonaItemObject={setAddedCertonaItemObject}
        />
      );
    default:
      return (
        <PartsCarousel
          title={title}
          partsList={partsList}
          pageType={pageType}
          greyBackground={greyBackground}
          isFromExternal={isFromExternal}
          onCertonaRecommendationClick={onCertonaRecommendationClick}
          addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
          skuDetailsData={skuDetailsData}
          cardsContainerCustomClass={cardsContainerCustomClass}
        />
      );
  }
};

export default CertonaPartsCarousel;
