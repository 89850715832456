/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import root from 'window-or-global';
import { type StackedPartCardContainerProps } from '../../interface';
import styles from './styles.module.scss';
import cx from 'classnames';
import azCommonStyles from '@/theme/globals.module.scss';
import { HorizontalPartCard } from '../HorizontalPartCard';
import { LargePartCard } from '../LargePartCard';
import { certonaPageType } from '@/constants/certonaPageType';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { useUtagReady } from '@/utils/analytics/useUtagReady';
import { useLabels } from '@/hooks/useLabels';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { useFeatureFlag } from '@/features/globalConfig';
import { getAddToCartInfo } from '../../utils/getAddToCartInfo';

const labelMap = {
  lblDelivery: 'label_threshold_delivery',
  lblSpend: 'label_threshold_spend',
  lblThresholdContainerHeader: 'label_ThresholdContainerHeader',
};

const StackedPartCardContainer = (props: StackedPartCardContainerProps) => {
  const {
    title,
    partsList,
    pricingInfo,
    contentsAligment = 'vertical',
    skuDetailsData,
    addToCartAnalyticsandSuccessMessage,
    onCertonaRecommendationClick,
    updateAfterAddtoCartClick,
    pageType,
    thresholdHeaderText,
    setAddedCertonaItemObject,
    inDrawer,
    certonaType,
    getCtjAnalytics,
  } = props;

  const { isUtagReady: isUtagDefined } = useUtagReady();

  const { lblThresholdContainerHeader } = useLabels(labelMap);
  const router = useRouter();
  const isPDP = router.pathname === '/products/[...slug]';
  const isHome = router.pathname === '/';
  const isShelf = router.pathname === '/[...seoUrlPath]';
  const isCart = router.pathname === '/cart';
  const isSearchPage = router.pathname.includes('search');
  const isRecommendationsLandingPage = router.asPath.includes(
    routePaths.landingPageRecommendations
  );
  const isPdpCertonaAddToCartEnabled =
    useFeatureFlag('IS_PDP_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const isHomePageCertonaAddToCartEnabled =
    useFeatureFlag('IS_HOME_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const isShelfPageCertonaAddToCartEnabled =
    useFeatureFlag('IS_SHELF_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const isSearchPageCertonaAddToCartEnabled =
    useFeatureFlag('IS_SEARCH_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const showAddtoCartCTA =
    inDrawer ||
    isCart ||
    isRecommendationsLandingPage ||
    (isPDP && isPdpCertonaAddToCartEnabled) ||
    (isHome && isHomePageCertonaAddToCartEnabled) ||
    (isShelf && isShelfPageCertonaAddToCartEnabled) ||
    (isSearchPage && isSearchPageCertonaAddToCartEnabled);
  const trackCertonaLinkInteractionBySection = (linkName: string, section: string, id: string) => {
    const utag = root.utag_data;
    const sectionName = `${section ?? utag?.pageType}_certona`;
    const data = {
      productLinkName: linkName,
      clickedProductId: id,
      pageName: root.utag_data?.pageName ?? '',
      pageType: root.utag_data?.pageType ?? '',
      siteSection: root.utag_data?.siteSection ?? '',
      eventType: sectionName.replace(/\s/g, '_').toLowerCase(),
    };
    clickTrack(data);
  };

  const handleCertonaClick = (linkName: string, id: string) => {
    onCertonaRecommendationClick?.();
    root.sessionStorage.setItem('productType', certonaType === 'CTJ' ? 'affinity' : 'certona');

    if (isUtagDefined === undefined && pageType !== certonaPageType.shoppingCartPage) {
      trackCertonaLinkInteractionBySection(linkName, pageType, id);
    }
  };

  return (
    <div
      className={cx(
        contentsAligment === 'vertical'
          ? styles.verticalStackedBlock
          : styles.horizontalStackedBlock,
        { [styles.inDrawer]: inDrawer }
      )}
      data-testid="carousel"
    >
      {!!title && (
        <>
          <h2
            className={cx(styles.partTitle, azCommonStyles['az-padding-bottom-4xs'])}
            data-testid="dynamic-content"
          >
            {title}
          </h2>
          <div className={styles.separator} />
        </>
      )}

      {!!thresholdHeaderText && (
        <>
          <h2 className={styles.thresholdHeaderText} data-testid="dynamic-content">
            {`${lblThresholdContainerHeader}`}
          </h2>
        </>
      )}
      <ul className={styles.cardsList}>
        {partsList.map(
          ({
            id,
            product_image_url,
            product_url_relative,
            product_name,
            total_review_count,
            average_rating,
            rating,
          }) => {
            const addToCartInfo = getAddToCartInfo({ skuDetailsData, id, thresholdHeaderText });

            return (
              <li key={id} className={styles.cardItem}>
                {contentsAligment === 'vertical' ? (
                  <LargePartCard
                    key={id}
                    part={{
                      properties: {
                        seoUrl: product_url_relative,
                        imageUrl: product_image_url,
                        makeModelYearPath: undefined,
                        refPageType: '',
                      },
                      label: product_name,
                      rating,
                      totalReviews: total_review_count,
                      averageRating: average_rating,
                    }}
                    pricingInfo={pricingInfo?.[id]}
                    addToCartData={addToCartInfo}
                    actionHandler={handleCertonaClick}
                    addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
                    setAddedCertonaItemObject={setAddedCertonaItemObject}
                    showAddtoCartCTA={showAddtoCartCTA}
                  />
                ) : (
                  <HorizontalPartCard
                    key={id}
                    part={{
                      properties: {
                        seoUrl: product_url_relative,
                        imageUrl: product_image_url,
                        makeModelYearPath: undefined,
                        refPageType: '',
                      },
                      label: product_name,
                      rating,
                      totalReviews: total_review_count,
                      averageRating: average_rating,
                    }}
                    pricingInfo={pricingInfo?.[id]}
                    addToCartData={addToCartInfo}
                    actionHandler={handleCertonaClick}
                    addToCartAnalyticsandSuccessMessage={addToCartAnalyticsandSuccessMessage}
                    updateAfterAddtoCartClick={updateAfterAddtoCartClick}
                    setAddedCertonaItemObject={setAddedCertonaItemObject}
                    showAddtoCartCTA={showAddtoCartCTA}
                    inDrawer={inDrawer}
                    cardStyle={'horizontal_stacked'}
                    certonaType={certonaType}
                    getCtjAnalytics={getCtjAnalytics}
                  />
                )}
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
};

export default StackedPartCardContainer;
