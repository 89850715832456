/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { type SkuAvailabilty } from '@/types/availability';

type AddToCartInfoProps = {
  skuDetailsData?: SkuAvailabilty[] | null;
  id?: string;
  thresholdHeaderText?: string;
};

export const getAddToCartInfo = (props: AddToCartInfoProps) => {
  const { skuDetailsData, id, thresholdHeaderText } = props;
  const skuDetails = skuDetailsData?.find((data: SkuAvailabilty) => {
    return data.skuId === id;
  });

  const bopusQuantity = skuDetails?.availabilityInfoVO.bopusQuantity;
  const sthQuantity = skuDetails?.availabilityInfoVO.sthQuantity;
  const nddQuantity = skuDetails?.availabilityInfoVO.nddQuantity;

  const fulFillmentId =
    !!bopusQuantity && bopusQuantity > 0
      ? FULFILLMENT_METHODS.STOREORDER
      : !!sthQuantity && sthQuantity > 0
      ? FULFILLMENT_METHODS.ONLINEORDER
      : null;

  const onlineOrderFulfillmentId =
    nddQuantity && nddQuantity > 0 ? FULFILLMENT_METHODS.NEXTDAY : FULFILLMENT_METHODS.ONLINEORDER;

  const addToCartInfo = {
    skuId: skuDetails?.skuId,
    productId: skuDetails?.attributesInfoVO?.productId,
    vehicleId: skuDetails?.catalogVehicleId ? skuDetails.catalogVehicleId : '',
    fulFillmentId: thresholdHeaderText ? onlineOrderFulfillmentId : fulFillmentId,
    brandName: skuDetails?.attributesInfoVO?.brandName,
    originalPartTypeId: skuDetails?.attributesInfoVO?.originalPartTypeId ?? 0,
    productPartNumber: skuDetails?.attributesInfoVO?.productPartNumber,
    productRepositoryId: skuDetails?.attributesInfoVO?.productRepositoryId,
  };

  return skuDetails ? addToCartInfo : {};
};
