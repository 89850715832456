/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type CertonaItem, type CertonaPricing } from '@/types';
import { type SkuAvailabilty } from '@/types/availability';

type AvailableCertonaProductsProps = {
  items?: CertonaItem[];
  skuDetailsData?: SkuAvailabilty[] | null;
  pricingInfo?: Record<string, CertonaPricing>;
  thresholdHeaderText?: string;
};

export const getAvailableCertonaProducts = (props: AvailableCertonaProductsProps) => {
  const { items, skuDetailsData, pricingInfo, thresholdHeaderText } = props;
  const availableProducts = items?.reduce<CertonaItem[]>((acc, item) => {
    //thresold recommendations container should only show available STH home products,Items which are only available for Same day delivery should not be included in the container
    const isAvailable = skuDetailsData?.some((skudata) => {
      const productAvailibility = thresholdHeaderText
        ? skudata.availabilityInfoVO.sthQuantity > 0
        : skudata.availabilityInfoVO.bopusQuantity > 0 ||
          skudata.availabilityInfoVO.sthQuantity > 0;
      // Customizable sku's products with no price should be still shown inside the certona container
      const pricingAvailability = !!pricingInfo?.[item.id]?.configurableSku
        ? skudata.skuId === item.id && productAvailibility
        : !!pricingInfo?.[item.id]?.totalPrice && productAvailibility && skudata.skuId === item.id;
      if (pricingAvailability) {
        return skudata;
      }
    });

    if (isAvailable) {
      acc = [...acc, item];
    }
    return acc;
  }, []);

  return availableProducts;
};
